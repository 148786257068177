import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/leather-cleaning';

export const query = graphql`
  query leatherCleaningPageQuery {
    leatherImage: file(relativePath: { regex: "/leather-cleaning.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 199) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const LeatherCleaningPage = ({ data }) => {
  return (
    <Layout>
      <Wrapper>
        <h1 className="underlined">Leather Cleaning</h1>
        <p>
          Leather that is properly maintained will last four–or even five–times
          longer than fabric or man-made fibers. Sci-Tech’s leather specialists
          have been thoroughly trained in how to properly identify your type of
          leather. As of January 2010, Robert McCutcheon, co-owner of Sci-Tech,
          was one of just over one thousand IICRC certified leather cleaners in
          the world. Sci-Tech only uses the most highly developed and world
          recognized leather care products developed by Leather Master.
        </p>
        <div style={{ margin: '20px 0' }}>
          <Img
            fixed={data.leatherImage.childImageSharp.fixed}
            style={{ float: 'left', marginRight: '20px' }}
          />
          <p>
            Regular cleaning and replenishment of natural oils will keep the
            leather in good condition for years to come. Oils in the leather
            will dry out and evaporate even when the leather is not regularly
            used. To keep the leather soft and flexible, dirt and grime which
            have accumulated on the surface must be removed, and the oils be
            periodically replaced. No matter how carefully you protect your
            leather, spills and other accidents can cause potential problems.
            Many stains can be easily removed if corrected immediately using the
            proper product. In most cases, home remedies that are tried only set
            the stain in deeper, making it impossible to remove. It’s important
            that stains be treated properly from the start.
          </p>
          <h2>Does Leather Need To Be Cleaned?</h2>
          <p>
            Leather is often sold as ‘bullet-proof’ furniture; rarely if ever
            needing cleaning or care. It is true, leather, when cared for
            correctly, will LONG outlast most fabric upholstery. However,
            leather will slowly soil and require cleaning. Some of these soils,
            like perspiration, body oils, and stains, will permanently stain and
            damage the leather if not cared for promptly. Also, leather requires
            regular moisturizing to prevent pealing and cracking and to keep
            that soft, supple feel that you love. We highly recommend that you
            have your leather furniture cleaned and conditioned every 6 months
            to a year.
          </p>
          <p>
            <strong>
              Did you know some leather cleaning kits that you can buy in the
              store can actually harm, rather than help, your leather?
            </strong>
          </p>
          <p>
            Leather furnishings require very specialized care and attention.
            Nearly all ‘over-the-counter’ leather cleaning products are
            ineffective or even worse, downright damaging to your leather.
            Sci-Tech uses cleaning techniques and products designed and used by
            the worlds’ largest leather tanneries.
          </p>
          <p>
            Those store-bought kits may leave your leather looking better in the
            short term, but they can break down the finish, which could lead to
            drying and cracking. Often they are made with solvents, silicone and
            lubricants; which can, over time, damage and dry out your leather.
            The products we use moisturize and protect your leather, which helps
            keep it soft and supple for years to come without any detrimental
            side effects.
          </p>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default LeatherCleaningPage;
